<template>
	<div class="app-container" style="height: 100%">
		<el-tabs class="tabhead" v-model="activeName">
			<el-tab-pane label="审批列表" v-if="permissionControl('ApprovalList')" lazy name="1"><auditlist></auditlist></el-tab-pane>
			<el-tab-pane label="审批设置" v-if="permissionControl('ApprovalSettings')" lazy name="2"><manage></manage></el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import auditlist from './auditList/list';
import manage from './auditManage/';
import { getUrlParams } from '@/utils/util';
export default {
	name: 'audit',
	components: {
		auditlist,
		manage
	},
	data() {
		return {
			activeName: ''
		};
	},
	methods: {},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		let params = getUrlParams(this.$route.query.params);

		if (params) {
			params = JSON.parse(params);
		}
		if (data) {
			this.activeName = data.activeName;
		}
		// eslint-disable-next-line no-void
		if (params.active != void 0) {
			this.activeName = params.active.toString();
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'ApprovalList' },
			{ name: '2', key: 'ApprovalSettings' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	}
};
</script>
<style lang="scss" scoped>
.app-container {
	height: 100%;
	// background: #f6f7fb;
	padding: 0;
}
</style>
<style lang="scss">
.tabhead {
	.el-tabs__header {
		padding: 15px 20px;
	}
}
</style>
