<template>
	<div class="container">
		<div class="topBox">
			<div class="topLeft">
				<div class="content all" :class="params.approvalStatus === '' ? 'active allactive' : ''" @click="handleSearch('')">
					<div class="Img"></div>
					<span>{{ counts.sum }}</span>
					<h4>全部</h4>
				</div>
				<div class="content waitaudit" :class="params.approvalStatus === '0' ? 'active waitauditactive' : ''" @click="handleSearch('0')">
					<div class="Img"></div>
					<span>{{ counts.waitApplication }}</span>
					<h4>待审批</h4>
				</div>
				<div class="content audit" :class="params.approvalStatus === '8' ? 'active auditactive' : ''" @click="handleSearch('8')">
					<div class="Img"></div>
					<span>{{ counts.ingApproval }}</span>
					<h4>审批中</h4>
				</div>
				<div class="content pass" @click="handleSearch('1')" :class="params.approvalStatus === '1' ? 'active passactive' : ''">
					<div class="Img"></div>
					<span>{{ counts.successfulApproval }}</span>
					<h4>已通过</h4>
				</div>
				<div class="content fail" @click="handleSearch('2')" :class="params.approvalStatus === '2' ? 'active failactive' : ''">
					<div class="Img"></div>
					<span>{{ counts.overruleApproval }}</span>
					<h4>不通过</h4>
				</div>
				<div class="content stop" @click="handleSearch('6')" :class="params.approvalStatus === '6' ? 'active stopactive' : ''">
					<div class="Img"></div>
					<span>{{ counts.stopApproval }}</span>
					<h4>停止</h4>
				</div>
				<div class="content overrule" @click="handleSearch('3')" :class="params.approvalStatus === '3' ? 'active overruleactive' : ''">
					<div class="Img"></div>
					<span>{{ counts.failApproval }}</span>
					<h4>撤销</h4>
				</div>
			</div>
		</div>
		<div style="width: 100%; background: #fff; padding: 20px; margin-top: 20px">
			<el-row>
				<el-col :span="6">
					<div class="inputBox">
						<span>发起人</span>
						<el-input v-model="params.applyUserName" placeholder="请输入发起人姓名" size="small"></el-input>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="inputBox">
						<span>发起部门</span>
						<treeselect
							:props="depProps"
							:options="deps"
							:value="depsId"
							:clearable="true"
							:accordion="true"
							:level="depsId"
							@getValue="getValue($event)"
							style="width: 100%"
						/>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="inputBox">
						<span>审批类型</span>
						<el-select clearable size="small" style="width: 100%" v-model="params.approvalType" placeholder="请选择">
							<el-option v-for="item in subTypeList" :key="item.dictId" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="inputBox">
						<span>审批发起日期</span>
						<el-date-picker
							v-model="params.applyTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							size="small"
							style="width: 100%"
						></el-date-picker>
					</div>
				</el-col>
				<!--<el-col :span="6">
          <div class="inputBox">
            <span>审批状态</span>
            <el-select
              clearable
              size="small"
              style="width: 100%;"
              v-model="params.approvalStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="item in statusList"
                :key="item.dictId"
                :label="item.dictName"
                :value="item.dictId"
              ></el-option>
            </el-select>
          </div>
        </el-col>!-->
			</el-row>
			<el-row style="margin-bottom: 10px">
				<el-col :span="6">
					<div class="inputBox">
						<span>操作时间</span>
						<el-date-picker
							v-model="params.approvalTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							size="small"
							type="date"
							style="width: 100%"
						></el-date-picker>
					</div>
				</el-col>
				<el-button type="primary" size="small" icon="el-icon-search" style="margin: 0px" @click="handleSearch()">查询</el-button>
			</el-row>

			<el-table
				:data="tableData.list"
				slot="empty"
				@row-click="handleInfoClick"
				v-loading="loadding"
				style="width: 100%"
				class="tableBackground"
			>
				<el-table-column type="index" label="序号" align="center" :width="50" :index="indexMethod"></el-table-column>
				<el-table-column prop="approvalTypeName" align="center" label="审批类型"></el-table-column>
				<el-table-column prop="choiceStaffName" align="center" label="员工"></el-table-column>
				<el-table-column prop="applyUserName" align="center" label="发起人"></el-table-column>
				<el-table-column prop="approvalDepartmentName" align="center" label="发起人部门" min-width="170"></el-table-column>
				<el-table-column prop="approvalStaffName" align="center" label="当前审批人"></el-table-column>
				<el-table-column prop="approvalTime" align="center" label="审批发起时间"></el-table-column>
				<el-table-column prop="applyTime" align="center" label="操作时间"></el-table-column>
				<el-table-column prop="approvalStatusName" align="center" label="审批状态">
					<template slot-scope="{ row }">
						<div :style="'color:' + returnStatusColor(row.approvalStatus)">
							{{ row.approvalStatusName }}
						</div>
					</template>
				</el-table-column>
				<!--<el-table-column fixed="right"
                         label="操作"
                         width="130px">
          <template slot-scope="scope">
            <el-button @click="(scope.row) "
                       type="text"
                       size="small">详情</el-button>
          </template>
        </el-table-column>!-->
			</el-table>
		</div>

		<div style="width: 100%; float: right; background: #fff">
			<el-pagination
				class="fr"
				style="margin: 20px"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import treeselect from '@/components/treeSelect/treeSelect';
import * as api from '@/api/audit';
export default {
	name: 'reward',
	components: {
		treeselect
	},
	data() {
		return {
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				applyUserName: '',
				approvalDepartmentId: '',
				approvalType: '',
				approvalStatus: '',
				applyTime: '',
				approvalTime: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: {},
			loadding: false,
			labelPosition: 'right',
			rowId: 0,
			subTypeList: [],
			statusList: [],
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			deps: this.$store.state.setData.depsList,
			depsId: '',
			counts: {
				failApproval: 0,
				overruleApproval: 0,
				staffNumber: 0,
				stopApproval: 0,
				successfulApproval: 0,
				waitApplication: 0,
				sum: 0
			}
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.subTypeList = this.$store.state.app.dict.filter((item) => item.groupId == 'subType');
			this.statusList = this.$store.state.app.dict.filter((item) => item.groupId == 'approvalStatus');
			this.getStatistics();
			this.handleSearch();
		});
	},
	methods: {
		// 部门选中的值
		getValue(eve) {
			if (eve === null) {
				this.depsId = '';
				this.params.approvalDepartmentId = '';
			} else {
				this.depsId = eve.id;
				this.params.approvalDepartmentId = eve.id;
			}
		},
		returnStatusColor(status) {
			// eslint-disable-next-line default-case
			switch (status) {
				case 1:
					return '#64C940';
				case 2:
					return '#FF604D';
				case 0:
					return '#409EFF';
			}
		},
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		getStatistics() {
			api.getStatistics({ request: {} }).then((res) => {
				this.counts = res;
			});
		},
		handleSearch(status) {
			this.loadding = true;
			this.params.approvalStatus = status;
			const params = {
				request: this.params
			};

			api.getApprovalList(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {});
		},
		handleInfoClick(row) {
			this.rowId = row.id;
			this.$router.push({
				path: '/audit_info',
				query: {
					id: this.rowId
				}
			});
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
	margin: 0 20px;
}
.toolBox {
	width: 100%;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
.topBox {
	width: 100%;
	height: 123px;
	color: #484848;
	.topLeft {
		width: 100%;
		height: 100%;
		padding: 5px 10px;
		display: inline-flex;
		margin-bottom: 10px;
	}
	.divider {
		vertical-align: baseline;
		height: 2em;
		display: inline-block;
		background: #dee2e9;
		width: 1px;
		margin: 0 8px;
		position: relative;
	}
	.content {
		display: inline-block;
		width: 13.185%;
		background-color: #f6f7fb;
		border-radius: 4px;
		text-align: center;
		padding: 25px 15px 15px;
		margin-right: 1.1%;
		line-height: 25px;
		margin-top: 10px;
		.Img {
			width: 36px;
			float: left;
			height: 36px;
			margin: 8px 0 0 10%;
			background-size: cover;
		}
		span {
			font-family: PingFangSC-Semibold;
			font-size: 32px;
			color: #484848;
			letter-spacing: -0.28px;
		}
		h4 {
			margin: 5px;
			font-weight: normal;
			font: bold 14px PingFangSC-Regular;
			color: #484848;
			letter-spacing: -0.25px;
			padding-left: 55%;
			text-align: left;
		}
	}
	.all {
		.Img {
			background-image: url('../../../assets/全部@3x.png');
		}
		span {
			color: #409eff;
		}
	}
	.all:hover,
	.allactive {
		.Img {
			background-image: url('../../../assets/全部-点击@3x.png');
		}
	}
	.waitaudit {
		.Img {
			background-image: url('../../../assets/待审批@3x.png');
		}
		span {
			color: #feb840;
		}
	}
	.waitaudit:hover,
	.waitauditactive {
		.Img {
			background-image: url('../../../assets/待审批-选中@3x.png');
		}
	}
	.audit {
		.Img {
			background-image: url('../../../assets/审批中@3x.png');
		}
		span {
			color: #f38a41;
		}
	}
	.audit:hover,
	.auditactive {
		.Img {
			background-image: url('../../../assets/审批中-点击@3x.png');
		}
	}
	.pass {
		.Img {
			background-image: url('../../../assets/已通过@3x.png');
		}
		span {
			color: #00d6bf;
		}
	}
	.pass:hover,
	.passactive {
		.Img {
			background-image: url('../../../assets/已通过-点击@3x.png');
		}
	}
	.fail {
		.Img {
			background-image: url('../../../assets/驳回@3x.png');
		}
		span {
			color: #ff7371;
		}
	}
	.fail:hover,
	.failactive {
		.Img {
			background-image: url('../../../assets/驳回-点击@3x.png');
		}
	}
	.stop {
		.Img {
			background-image: url('../../../assets/终止@3x.png');
		}
		span {
			color: #909399;
		}
	}
	.stop:hover,
	.stopactive {
		.Img {
			background-image: url('../../../assets/终止-选中@3x.png');
		}
	}
	.overrule {
		.Img {
			background-image: url('../../../assets/撤销@3x.png');
		}
		span {
			color: #909399;
		}
	}
	.overrule:hover,
	.overruleactive {
		.Img {
			background-image: url('../../../assets/撤销-选中@3x.png');
		}
	}
	.content:hover,
	.active {
		background-color: #498df0;
		span,
		h4 {
			color: #fff;
		}
	}
}
// .inputBox {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//   span {
//     width: 100px;
//     text-align: right;
//     margin-right: 10px;
//   }
// }
</style>
<style lang="scss">
@media (max-width: 1250px) {
	.container .topBox .content h4 {
		padding-left: 47.16%;
		transform: translateX(7px);
	}
}
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
