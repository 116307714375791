<template>
	<div class="container" style="padding: 25px">
		<div class="boxes">
			<el-row :gutter="20">
				<el-col :span="6" v-for="(box, index) in boxList" :key="index">
					<div class="box">
						<div class="seticon">
							<el-dropdown trigger="click" @command="handleCommand">
								<span class="el-dropdown-link">
									<i class="el-icon-setting"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<template v-if="notShowFormSubType.includes(box.subType)">
										<el-dropdown-item :command="steps('base', box)">基本信息</el-dropdown-item>
										<el-dropdown-item :command="steps('flow', box)" :disabled="box.initStep < 2">审批流</el-dropdown-item>
									</template>
									<template v-else>
										<el-dropdown-item :command="steps('base', box)">基本信息</el-dropdown-item>
										<el-dropdown-item :command="steps('form', box)" :disabled="box.count == 0 || box.initStep < 2"
											>审批表单</el-dropdown-item
										>
										<el-dropdown-item :command="steps('flow', box)" :disabled="box.initStep < 2">审批流</el-dropdown-item>
										<!-- <el-dropdown-item :command="steps('set', box)" :disabled="box.initStep < 3">高级设置</el-dropdown-item> -->
									</template>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="setBox">
							<div class="boxicon">
								<div class="boxicon-img">
									<img v-if="box.subType == 1" src="../../../assets/approval/转正@3x.png" alt />
									<img v-if="box.subType == 2" src="../../../assets/approval/延长试用期@3x.png" alt />
									<img v-if="box.subType == 3" src="../../../assets/approval/调岗@3x.png" alt />
									<img v-if="box.subType == 4" src="../../../assets/approval/离职@3x.png" alt />
									<img v-if="box.subType == 5" src="../../../assets/approval/奖惩@3x.png" alt />
									<img v-if="box.subType == 6" src="../../../assets/approval/晋升@3x.png" alt />
									<img v-if="box.subType == 7" src="../../../assets/approval/其他@3x.png" alt />
									<img v-if="box.subType == 8" src="../../../assets/approval/转正申请@3x.png" alt />
									<img v-if="box.subType == 9" src="../../../assets/approval/调薪@3x.png" alt />
									<img v-if="box.subType == 10" src="../../../assets/approval/核心人力@3x.png" alt />
									<img v-if="box.subType > 10" src="../../../assets/approval/其他@3x.png" alt />
								</div>
							</div>
							<p style="min-width: 80px; max-width: 100px; text-align: left">{{ box.approvalName }}</p>
							<div style class="box-Btn">
								<el-switch
									:disabled="box.approvalInitStatus"
									@change="switchStatus(box.approvalSwitchStatus, box.approvalInfoId)"
									:active-value="1"
									:inactive-value="0"
									v-model="box.approvalSwitchStatus"
								></el-switch>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="box" @click="handleCreate" style="height: 132px; padding-top: 20px">
						<div class="newBox">
							<svg-icon class="svg" icon-class="其他"></svg-icon>
							<!-- <i class="el-icon-plus" style="color: #498DF0;"></i> -->
							<span style="font-size: 16px; color: #2b3642">新建审批</span>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<el-dialog title="基本信息" :close-on-click-modal="false" :visible.sync="dialogFormVisible" center>
			<el-form :model="auditForm" ref="auditForm" :rules="rules">
				<el-form-item label="审批名称" prop="approvalName" :label-width="formLabelWidth">
					<el-input
						v-model="auditForm.approvalName"
						style="width: 90%"
						placeholder="1-20个字，不能与现有审批名称重复"
						maxlength="20"
						autocomplete="off"
					></el-input>
				</el-form-item>
				<el-form-item label="发起人" prop="sponsor" :label-width="formLabelWidth" style="margin-bottom: 10px">
					<el-checkbox label="部门负责人可发起" v-model="auditForm.sponsorType" :true-label="1" :false-label="0" name="type"></el-checkbox>
					<el-tooltip class="item" effect="dark" content="所选部门范围中，只有部门负责人可发起" placement="right">
						<i class="el-icon-question" style="color: #409eff"></i>
					</el-tooltip>
					<div>
						<span v-show="auditForm.orgList.length > 0">部门：</span>
						<el-tag
							style="margin-right: 10px"
							v-for="(tag, index) in auditForm.orgList"
							:key="tag.id"
							closable
							@close="handleOrgClose(tag, index)"
							type="primary"
							>{{ tag.orgName }}</el-tag
						>
					</div>
					<div>
						<span v-show="auditForm.userList.length > 0">员工：</span>
						<el-tag
							style="margin-right: 10px"
							v-for="(tag, tagiIndex) in auditForm.userList"
							:key="tagiIndex"
							closable
							@close="handleUserClose(tag, tagiIndex)"
							type="primary"
							>{{ tag.staffName || tag.name }}</el-tag
						>
					</div>
					<div v-if="auditForm.roleList.length > 0">
						<span>角色：</span>
						<el-tag
							style="margin-right: 10px"
							v-for="(tag, tagiIndex) in auditForm.roleList"
							:key="tagiIndex"
							closable
							@close="handleRoleClose(tag, tagiIndex)"
							type="primary"
							>{{ tag.name }}</el-tag
						>
					</div>
					<depts
						@updateTag="updateTag"
						:showStaff="true"
						:checkStrictly="true"
						:setChecks="orgIds"
						:userList="auditForm.userList"
						@updateStaffTag="updateStaffTag"
					></depts>
					<el-popover placement="bottom" width="200" trigger="click">
						<el-tree
							:data="roleOptions"
							show-checkbox
							node-key="id"
							:check-strictly="true"
							ref="roleOptions"
							:props="{ label: 'name' }"
							@check="checkRole"
						></el-tree>
						<el-button size="mini" icon="el-icon-plus" slot="reference" type="text">添加角色</el-button>
					</el-popover>
				</el-form-item>
				<el-form-item label="审批流权限" :label-width="formLabelWidth">
					<el-radio-group v-model="auditForm.modifyStatus">
						<el-radio label="0">禁止编辑</el-radio>
						<el-radio label="1">编辑</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="审批意见" :label-width="formLabelWidth" prop="opinionStatus">
					<el-radio-group v-model="auditForm.opinionStatus">
						<el-radio :label="0">无需填写“审批意见”</el-radio>
						<el-radio :label="2">“审批意见”非必填</el-radio>
						<el-radio :label="1">“审批意见”为必填项</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="审批说明" :label-width="formLabelWidth" prop="approvalDescription">
					<el-input
						style="width: 90%"
						type="textarea"
						maxlength="100"
						show-word-limit
						v-model="auditForm.approvalDescription"
						class="input_textarea"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="text-align: center" class="dialog-footer">
				<el-button style="width: 98px" @click="closeModal">取 消</el-button>
				<el-button type="primary" @click="saveConfig">新增审批</el-button>
			</div>
		</el-dialog>
		<!-- 审批设置弹框 -->
		<approvalSetDialog
			:visible="approvalSetVisible"
			:subType="dialogData.subType"
			@close="approvalSetVisible = false"
			:activeType="dialogData.active"
			:approvalInfoId="dialogData.approvalInfoId"
			:haveForm="dialogData.haveForm"
			@refresh="getApprovalAll"
		/>
	</div>
</template>

<script>
import * as api from '@/api/audit';
import depts from '@/components/departs';
import approvalSetDialog from '../../organizingpersonnel/organizing/organizational/approval/approvalSetDialog.vue';
export default {
	name: 'auditManage',
	components: {
		depts,
		approvalSetDialog
	},
	data() {
		const check = (rule, value, callback) => {
			if (this.auditForm.orgList.length == 0 && this.auditForm.userList.length == 0 && this.auditForm.roleList.length == 0) {
				return callback(new Error('请选择部门,人员或者角色'));
			}
			callback();
		};

		return {
			value: true,
			dialogFormVisible: false,
			orgIds: [],
			roleOptions: [],
			auditForm: {
				approvalDescription: '',
				approvalInitStatus: '',
				approvalName: '',
				approvalSwitchStatus: '',
				approvalType: '',
				id: '',
				initStep: '',
				opinionStatus: '',
				orgList: [],
				sponsorType: '',
				// subType: '7',
				userList: [],
				roleList: [],
				power: '0',
				modifyStatus: '0'
			},
			clearForm: {},
			formLabelWidth: '120px',
			rules: {
				approvalName: [{ required: true, message: '请输入审批名称', trigger: 'blur' }],
				// subType: [{ required: true, message: '请选择类型', trigger: 'change' }],
				sponsor: [{ validator: check, trigger: 'blur' }],
				opinionStatus: [{ required: true, message: '请选择', trigger: 'blur' }]
			},
			boxList: [],
			approvalSetVisible: false,
			dialogData: {},
			// 不展示表单设置类型
			notShowFormSubType: [8, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
			// subTypeList: []
		};
	},
	mounted() {
		this.getRoleOptions();
		this.$nextTick(() => {
			// this.subTypeList = this.$store.state.app.dict.filter(function (item) {
			//   return item.groupId == "subType";
			// })
		});
		this.clearForm = { ...this.auditForm };
		this.getApprovalAll();
	},
	methods: {
		// 部门
		// queryDepartment() {
		//   sysApi
		//     .queryDepartment({
		//       request: {}
		//     })
		//     .then(res => {
		//       this.$store.commit('SET_DEPT', res)
		//     })
		//     .catch(err => {
		//       console.log(err)
		//     })
		// },
		checkRole(data, { checkedNodes }) {
			this.auditForm.roleList = checkedNodes;
			this.$refs['auditForm'].clearValidate('sponsor');
		},
		openRoleOptions() {},
		getRoleOptions() {
			api.getRoleOptions().then((res) => {
				if (res && res.length > 0) {
					this.roleOptions = res.map((v) => ({
						id: v.roleId,
						name: v.roleName
					}));
				}
			});
		},
		handleCreate() {
			this.dialogFormVisible = true;
			// this.approvalSetVisible = true;
			// this.dialogData = {
			// 	haveForm: true
			// };
			// return;
			this.$nextTick(() => {
				this.$refs['auditForm'].clearValidate();
				this.$refs['auditForm'].resetFields();
			});
			this.auditForm = Object.assign(this.auditForm, {
				id: '',
				orgList: [],
				userList: [],
				roleList: []
			});
			this.auditForm.sponsorType = 0;
		},
		updateTag(data) {
			this.orgIds = [];
			for (const org of data) {
				this.orgIds.push(org.id);
			}
			this.auditForm.orgList = data;
			if (this.auditForm.orgList.length > 0) {
				this.$refs['auditForm'].clearValidate();
			}
		},
		updateStaffTag(data) {
			this.auditForm.userList = data;
			// this.auditForm.userList = this.auditForm.userList.concat(data);
			if (this.auditForm.userList.length > 0) {
				this.$refs['auditForm'].clearValidate();
			}
		},
		// 获取所有表单
		getApprovalAll() {
			return api.getApprovalAll({ request: { pageNo: 1, pageSize: 100 } }).then((res) => {
				this.boxList = res.list;
				for (const box of this.boxList) {
					if (box.approvalInitStatus == 0) {
						box.approvalInitStatus = true;
					} else {
						box.approvalInitStatus = false;
					}
				}
				return res.list;
			});
		},
		steps(type, row) {
			return {
				type,
				row
			};
		},
		handleRoleClose(node, index) {
			this.$refs.roleOptions.setChecked(node.id, false);
			this.auditForm.roleList.splice(index, 1);
		},
		handleCommand(com) {
			// if (com.row?.subType > 10 && com.row?.subType < 15) {
			this.dialogData = {
				subType: com.row.subType.toString(),
				// eslint-disable-next-line no-nested-ternary
				active: com.type === 'form' ? 1 : com.type === 'flow' ? 2 : 0,
				approvalInfoId: com.row.approvalInfoId,
				haveForm: !this.notShowFormSubType.includes(com.row?.subType)
			};
			this.approvalSetVisible = true;
			// return;
			// }
			// api.getConfig({ request: { approvalInfoId: com.row.approvalInfoId } }).then((res) => {
			// 	this.auditForm = res;
			// 	if (res.roleList && res.roleList.length > 0) {
			// 		this.$refs.roleOptions.setCheckedKeys(
			// 			res.roleList.map((v) => `${v.id}`),
			// 			true
			// 		);
			// 	} else {
			// 		this.$refs.roleOptions.setCheckedKeys([]);
			// 	}
			// });
			// if (com.type == 'base') {
			// 	this.dialogFormVisible = true;
			// 	this.$nextTick(() => {
			// 		this.$refs['auditForm'].clearValidate();
			// 	});
			// } else if (com.type == 'form') {
			// 	this.$router.push({
			// 		path: '/formStep',
			// 		query: {
			// 			id: com.row.approvalInfoId,
			// 			type: com.row.approvalType,
			// 			approvalName: com.row.approvalName,
			// 			opinionStatus: com.row.opinionStatus,
			// 			step: 0
			// 		}
			// 	});
			// } else if (com.type == 'flow') {
			// 	this.$router.push({
			// 		path: '/formStep',
			// 		query: {
			// 			id: com.row.approvalInfoId,
			// 			type: com.row.approvalType,
			// 			approvalName: com.row.approvalName,
			// 			opinionStatus: com.row.opinionStatus,
			// 			step: 1
			// 		}
			// 	});
			// } else if (com.type == 'set') {
			// 	this.$router.push({
			// 		path: '/formStep',
			// 		query: {
			// 			id: com.row.approvalInfoId,
			// 			type: com.row.approvalType,
			// 			approvalName: com.row.approvalName,
			// 			opinionStatus: com.row.opinionStatus,
			// 			step: 2
			// 		}
			// 	});
			// } else if (com.type == 'preview') {
			// 	this.$router.push({
			// 		path: '/formStep',
			// 		query: {
			// 			id: com.row.approvalInfoId,
			// 			type: com.row.approvalType,
			// 			approvalName: com.row.approvalName,
			// 			opinionStatus: com.row.opinionStatus,
			// 			step: 3
			// 		}
			// 	});
			// }
		},
		saveConfig() {
			this.$refs['auditForm'].validate((valid) => {
				if (valid) {
					api.saveConfig({ request: this.auditForm }).then((res) => {
						if (res._responseStatusCode != 0) {
							return;
						}
						this.dialogFormVisible = false;
						// 获取所有
						this.getApprovalAll().then((r) => {
							const target = r.find(({ approvalInfoId }) => approvalInfoId === res.id);

							if (target) {
								this.dialogData = {
									subType: target.subType,
									active: 1,
									approvalInfoId: target.approvalInfoId,
									haveForm: true
								};
								this.approvalSetVisible = true;
							}
						});
						// let id = '';

						// if (this.auditForm.id != '') {
						// 	id = this.auditForm.id;
						// } else {
						// 	id = res.id;
						// }

						// this.$router.push({
						// 	path: '/formStep',
						// 	query: {
						// 		id,
						// 		type: this.auditForm.approvalType,
						// 		approvalName: this.auditForm.approvalName,
						// 		opinionStatus: this.auditForm.opinionStatus,
						// 		step: 0
						// 	}
						// });
						this.$refs['auditForm'].clearValidate();
						this.$refs['auditForm'].resetFields();
						this.auditForm = this.clearForm;
						this.dialogFormVisible = false;
					});
				}
			});
		},
		// 开关接口
		switchStatus(status, id) {
			api.switchStatus({
				request: {
					approvalInfoId: id,
					approvalSwitchStatus: status
				}
			}).then((res) => {
				if (res.code == 0) {
					this.getApprovalAll();
				}
			});
		},
		closeModal() {
			this.$refs['auditForm'].clearValidate();
			this.$refs['auditForm'].resetFields();
			this.auditForm = this.clearForm;
			this.dialogFormVisible = false;
		},
		handleOrgClose(tag, index) {
			this.auditForm.orgList.splice(this.auditForm.orgList.indexOf(tag), 1);
			this.orgIds.splice(index, 1);
		},
		handleUserClose(tag) {
			this.auditForm.userList.splice(this.auditForm.userList.indexOf(tag), 1);
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	height: 100%;
	flex-wrap: wrap;
	.boxes {
		display: flex;
		flex-flow: row wrap;
	}

	.box {
		// background: #fff;
		background-color: #f6f7fb;
		margin-bottom: 20px;
		border-radius: 5px;
		position: relative;
		padding: 10px 0 40px 0;
		.seticon {
			text-align: right;
			padding-right: 10px;
			color: #909399;
			i {
				font-size: 20px;
			}
		}
		.setBox {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-direction: row;
			.boxicon {
				text-align: center;
				// margin-right: 20px;
				.boxicon-img {
					text-align: center;
				}
				img {
					width: 60px;
					height: 60px;
					// width: 100%;
				}
				p {
					margin: 0;
					margin-top: 20px;
					font-size: 18px;
				}
				.box-Btn {
					text-align: center;
				}
			}
		}
		.newBox {
			font-size: 40px;
			color: #eee;
			text-align: center;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&:hover {
			cursor: pointer;
		}
	}
}
.svg {
	width: 50px;
	height: 40px;
}
</style>
